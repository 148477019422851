:root {
  --color-dark-theme-background: #1c1b22;
  --color-dark-theme-foreground: #fbfbfe;
  --color-dark-theme-link: #00ddff;
  --color-light-theme-background: #fff;
  --color-light-theme-foreground: #15141a;
  --color-light-theme-link: #0061e0;
  --color-link: var(--color-light-theme-link);
  --color-main-background: var(--color-light-theme-background);
  --color-main-foreground: var(--color-light-theme-foreground);

  @media (prefers-color-scheme: dark) {
    --color-link: var(--color-dark-theme-link);
    --color-main-background: var(--color-dark-theme-background);
    --color-main-foreground: var(--color-dark-theme-foreground);
  }
}

/* Base Styles */

html,
body {
  align-items: center;
  background-color: var(--color-main-background);
  color: var(--color-main-foreground);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  min-height: 100%;
}

.container {
  font-family: -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
  font-size: 1.3rem;
  max-width: 40em;
  padding: 1rem;
}

.avatar {
  border-radius: 50%;
  height: 100px;
  margin-bottom: 0.5rem;
  width: 100px;
}

.avatar.is-inverted {
  transform: rotate(180deg);
}

h1 {
  margin-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-main-foreground);
}

a:link,
a:visited {
  color: var(--color-link);
  text-decoration: underline;
}

a:hover,
a:active,
a:focus {
  text-decoration: none;
}

p {
  line-height: 1.3;
  margin-bottom: 1.5rem;
}

ul {
  padding-left: 1rem;
  margin-bottom: 1.5rem;
}

ul.bio {
  list-style-type: none;
  padding: 0;
}

li {
  line-height: 1.3;
  margin-bottom: 0.5rem;
}

small {
  font-size: 0.9rem;
}

footer {
  margin-top: 2rem;
}

footer p {
  margin-bottom: 0;
}

/* Animation */

@keyframes a-slide-up {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.a-slide-up {
  animation: a-slide-up 600ms ease 0ms backwards;
}

h1.a-slide-up {
  animation-delay: 100ms;
}

ul.bio.a-slide-up {
  animation-delay: 200ms;
}

ul.social.a-slide-up {
  animation-delay: 300ms;
}

footer.a-slide-up {
  animation-delay: 400ms;
}

@media (prefers-reduced-motion: reduce) {
  .a-slide-up {
    animation: none;
  }
}
